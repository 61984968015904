export const environment = {
  applicationStageCount: 8,
  apiUrl: 'https://api.scan.prolinkplus.prolinksolutions.com/api',
  clientName: 'prolink',
  cognitoAuthenticationFlowtype: 'USER_SRP_AUTH',
  cognitoRegion: 'us-east-1',
  cognitoUserPoolId: 'us-east-1_L0ppodJka',
  cognitoUserPoolWebClientId: '3df0n44oh3r71pnr82qf0ac9s6',
  googleAnalyticsId: '',
  hotjarId: '',
  loadZenDesk: false,
  production: false,
  allowLanguageChanges: true,
  recaptchaSiteKey: '6LfbqTUbAAAAANRLGLTL7l3E6_e126BPBOZTnUF0',
  setTimeout: 30,
  smartyStreetsKey: '104582367074633472',
  stateAbbreviation: 'AL',
  stateId: 1,
  timeOut: 600000,
  timestamp: '11/16/2023 9:59:05 PM',
  title: 'ProLink+',
  uploadFileSize: 50000000,
  zenDeskUrl: '',
  showReasonableAccommodation: false,
  showLanguageAssistance: false,
  showDiscriminationComplaints: false,
  showLoginPageHeroBanner: true,
  supportedLanguages: [ { name: 'English', value:'en'}, { name: 'Español', value:'es'}]
};